import { mapObjectValues } from '../Utils'
import {
  API_DEVICE_MANAGER,
  API_DEVICE_OPERATOR,
  API_DEVICE_PATCH_SERVICE,
  API_DEVICE_USER,
  API_DHC,
  API_MARKET,
  API_ORBIT_SETTING_SERVICE,
  API_SECURITY_SERVICE,
  API_SIEM,
  API_SUPPORT,
  API_SURVEY
} from '../config'
import fetchRequest from './fetch'

// upload server api timeout
const UPLOADTIMEOUT = 60000

const TOO_LONG_TIMEOUT = 1000 * 60 * 2
// custom api methods
/**
 * @Desc: 用户登录
 * @param loginInfo
 * @returns {AxiosPromise}
 */
export function loginApi(loginInfo) {
  const data = {
    ...mapObjectValues(loginInfo),
    remmberMe: loginInfo.remmberMe || false
  }
  // 龙武登录space需要一个key
  if (data.key) {
    data.key = loginInfo.key
  }
  // 成龙需要知道是zendesk过来
  if (data.from) {
    data.from = loginInfo.from
  }

  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/login',
    method: 'post',
    data: data
  })
}

/**
 * @Desc: 用户注册
 * @param registerInfo
 * @returns {AxiosPromise}
 */
export function userRegister(registerInfo) {
  const data = {
    ...mapObjectValues(registerInfo)
  }
  if (data.key) {
    data.key = registerInfo.key
  }
  if (data.brandId) {
    data.brandId = registerInfo.brandId
  }
  if (data.returnTo) {
    data.returnTo = registerInfo.returnTo
  }
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/register',
    method: 'post',
    data: data
  })
}

/**
 * @Desc: 用户退出登录
 * @param userKey
 * @returns {AxiosPromise}
 */
export function logoutApi(userKey) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/signOut',
    method: 'post',
    data: { userKey: userKey }
  })
}

/**
 * @Desc 重置密码邮箱部分
 * @param passwordInfo
 * @returns {AxiosPromise}
 */
export function resetPassword(passwordInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/catch/sendresetpassword',
    method: 'post',
    data: passwordInfo
  })
}

/**
 * @Desc 重置密码密码部分
 * @param modifyInfo
 * @returns {Promise}
 */
export function resetNewPassword(modifyInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/catch/resetnewpassword',
    method: 'post',
    data: modifyInfo
  })
}

/**
 * @url: basecountry/loadList
 * @returns {AxiosPromise}
 * @DESC: '获取国家信息'
 */
// export function getCountryFlagAndId(baseInfo) {
//   return fetchRequest({
//     url: '/basecountry/loadList',
//     method: 'post',
//     data: baseInfo
//   })
// }

/**
 *
 * @param key userKey: 登录后接口返回
 * @returns {AxiosPromise}
 * @Desc: 根据userKey获取用户详细详细
 */
export function loadUserApi(key) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/user/loadUserInfo',
    method: 'post',
    data: {
      userKey: key
    }
  })
}

/**
 * @Desc 获取侧边栏数据
 * @param paramInfo
 * @returns {*}
 */
export function getSidebarLists(paramInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/roleinfo/getMenu',
    method: 'get',
    params: paramInfo
  })
}

/**
 * @param key userKey: 登录后接口返回
 * @returns {AxiosPromise}
 * @Desc: 获取sideBar树形数据
 */
export function loadLocationData(key) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/deviceBaseInfo/loadLocationData',
    method: 'post',
    data: { userKey: key }
  })
}

/**
 *
 * @param key userKey: 登录后接口返回
 * @returns {AxiosPromise}
 * @Desc: dashBord页面数据信息
 */
export function loadDashBoardInfoLists(key) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/statistics/loadDashBoard',
    method: 'post',
    data: { userKey: key }
  })
}

export function deviceDashboardCount(deviceInfo = null) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/deviceBaseInfo/deviceDashboardCount',
    method: 'post',
    data: deviceInfo
  })
}
/**
 * @param paramInfo = {userKey: String, page: Number, limit: Number}
 * @returns {AxiosPromise}
 * @Desc 统计-设备升级日志
 */
export function loadDeviceInstallLog(paramInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/statistics/loadDeviceInstallLog',
    method: 'post',
    data: paramInfo
  })
}

/**
 * @param queryBase
 * @returns {AxiosPromise}
 * @Desc 获取设备列表
 */

export function getDeviceLists(queryBase) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/deviceBaseInfo/loadPager/byLocation',
    method: 'post',
    data: queryBase
  })
}

/**
 * @param queryBase
 * @returns {AxiosPromise}
 * @Desc 删除设备
 */

export function removeAccountDevice(queryBase) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/deviceBaseInfo/removeAccountDevice',
    method: 'post',
    data: queryBase
  })
}

/**
 * @param queryBase
 * deviceId, functionCode
 * @returns {AxiosPromise}
 * @Desc 获取该设备硬件是否支持某功能，
 * 例如 functionCode 为 cmd_device_inactivate_46 是否支持反激活，如果不支持，必须恢复出厂设置
 */

export function getFeatureAvailability(queryBase) {
  return fetchRequest({
    baseURL: API_ORBIT_SETTING_SERVICE,
    // baseURL: API_DEVICE_OPERATOR,
    url: '/v1/feature/availability',
    // url: '/operator/fetchFeatureAvailability',
    method: 'get',
    params: queryBase
  })
}

/**
 * @Desc 更新用户信息
 * @param userInfo
 * @returns {AxiosPromise}
 */
export function updateUserInfo(userInfo) {
  return fetchRequest({
    url: '/user/update',
    method: 'post',
    data: userInfo
  })
}

/**
 * @Desc 获取用户账单列表
 * @param paramsInfo
 * @returns {AxiosPromise}
 */
export function getUserPlanLists(paramsInfo) {
  return fetchRequest({
    url: '/billing/queryPlanPageByUser',
    method: 'post',
    data: paramsInfo
  })
}

/**
 * @Desc 判断设备是否锁定
 * @param dataInfo
 * @returns {AxiosPromise}
 */
export function decideDeviceLockState(dataInfo = {}) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    // url: '/iot-service/v1/checklockaddress',
    url: '/activation/checklockaddress',
    method: 'post',
    data: dataInfo
  })
}

/**
 * @Desc 验证用户验证码
 * @param codeInfo
 * @returns {AxiosPromise}
 */
export function verificationCode(codeInfo) {
  return fetchRequest({
    url: '/iot-service/v1/verificationcode',
    method: 'post',
    data: codeInfo
  })
}

/**
 * @Desc 验证用户验证码 4位激活码 销售demo
 * @param codeInfo
 * @returns {AxiosPromise}
 */
export function verificationCodeSalesDemo(codeInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/sales/demoActive',
    method: 'post',
    data: codeInfo
  })
}

/**
 * @Desc 激活设备重发、重置密码
 * @param baseInfo
 * @param code
 * @returns {AxiosPromise}
 */
export function resendOrEditEmail(baseInfo, code = null) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/deviceuserbind/sendMail',
    method: 'post',
    data: { ...mapObjectValues(baseInfo), token: code }
  })
}

/**
 * @Desc 用户补充信息
 * @param baseInfo
 * @param other
 * @returns {AxiosPromise}
 */
export function replenishUserInfo(baseInfo, other) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/deviceuserbind/save',
    method: 'post',
    data: { ...mapObjectValues(baseInfo), ...other }
  })
}

/**
 * @Desc 获取激活码
 * @param queryInfo
 * @returns {AxiosPromise}
 */
export function getActivationCode(queryInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/activationCode/getSixDigitActiveCode',
    method: 'post',
    data: queryInfo
  })
}

/**
 * @Desc 获取剩余的激活码数量
 * @param data
 * @returns {AxiosPromise}
 */

export function getCodeNumByUser(data) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/activationCode/getCodeNumByUser',
    method: 'post',
    data: data
  })
}

/**
 * @Desc 获取国家code、名称
 * @param info
 * @returns {AxiosPromise}
 */
export function getCountryLists() {
  return fetchRequest({
    url: '/basecountry/loadList',
    method: 'get'
  })
}

/**
 * @Desc 发送验证码到邮箱
 * @param codeInfo
 * @returns {AxiosPromise}
 */
export function sendActivationCode(codeInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    // url: '/deviceactivationcode/sendActivationCode',
    url: '/activationCode/sendActivationCode',
    method: 'post',
    data: codeInfo
  })
}

/**
 * @Desc 获取设备详情
 * @param deviceInfo
 * @returns {AxiosPromise}
 */
export function getDeviceDetails(deviceInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/deviceBaseInfo/loadDeviceInfoDto',
    method: 'post',
    data: deviceInfo
  })
}

/**
 * @Desc 更新某一台设备信息
 * @param updateInfo
 * @returns {AxiosPromise}
 */
export function updateDeviceInfo(updateInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/deviceBaseInfo/updateDeviceLocationInfo',
    method: 'post',
    data: updateInfo
  })
}

/**
 * @Desc 更新某一台设备名称
 * @param updateInfo
 * @returns {AxiosPromise}
 */
export function updateDeviceName(updateInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/deviceBaseInfo/updateDeviceName',
    method: 'post',
    data: updateInfo
  })
}

/**
 * @Desc 获取全局设置基础信息
 * @param queryInfo
 * @returns {AxiosPromise}
 */
export function getGlobalSettingInfo(queryInfo = {}) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/deviceselftchecktimingconfig/load',
    method: 'post',
    data: queryInfo
  })
}

/**
 * @Desc 更新自检时间
 * @param timeInfo
 * @returns {AxiosPromise}
 */
export function updateHealthCheckTime(timeInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/deviceselftchecktimingconfig/updateCheckTime',
    method: 'post',
    data: timeInfo
  })
}
/**
 * @Desc 设置自检状态
 * @param statusInfo
 * @returns {AxiosPromise}
 */
export function setHealthCheckState(statusInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/deviceselftchecktimingconfig/updateCheckSwitch',
    method: 'post',
    data: statusInfo
  })
}

/**
 * @Desc 更新发送报告时间
 * @param timeInfo
 * @returns {AxiosPromise}
 */
export function updateReportTime(timeInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/deviceselftchecktimingconfig/updateReportTime',
    method: 'post',
    data: timeInfo
  })
}

/**
 * @Desc 设置发送自检报告状态
 * @param statusInfo
 * @returns {AxiosPromise}
 */
export function setReportState(statusInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/deviceselftchecktimingconfig/updateReportSwitch',
    method: 'post',
    data: statusInfo
  })
}

/**
 * @Desc 设置账号下设备自动升级开关
 * @param statusInfo
 * @returns {AxiosPromise}
 */
export function setAutoUpdate(statusInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/deviceselftchecktimingconfig/updateAutoUpdate',
    method: 'post',
    data: statusInfo
  })
}

/**
 * @Desc 设置账号 Teams Byod 开关
 * @param statusInfo
 * @returns {AxiosPromise}
 */
export function setTeamsByod(statusInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: `/deviceselftchecktimingconfig/teamsByod?enabled=${statusInfo.status ? 1 : 0}`,
    method: 'put'
  })
}

export function autoUpdateTime(timeInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/deviceselftchecktimingconfig/updateUpgradeTime',
    method: 'post',
    data: timeInfo
  })
}

/**
 * @description: 修改patch安装时间
 * @param {*} timeInfo
 * @return {*}
 */
export function updateInstallPatchTime(timeInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/deviceselftchecktimingconfig/updateInstallPatchTime',
    method: 'post',
    data: timeInfo
  })
}

/*Device Actions Api Start*/

/**
 * @Desc 设备列表操作接口
 * @param uri   Report Issue       '/devicebaseinfo/deviceSendLog'
 * @param uri   Repair             '/devicebaseinfo/repair'
 * @param uri   Quick Check        '/devicebaseinfo/update/selfcheck'
 * @param uri   Restart            '/devicebaseinfo/deviceRestart'
 * @param dataBase
 * @returns {AxiosPromise}
 */

export const ACTION_URI_INFO = {
  'Report Issue': '/operator/deviceSendLog',
  Repair: '/repairs/device-id',
  'Quick Check': '/self-checks/device-id',
  Restart: '/operator/deviceRestart'
}

/* 
销售演示demo quick check 和 repair 接口
*/
export const ACTION_URI_INFO_DEMO = {
  Repair: '/operator/repair',
  'Quick Check': '/operator/selfCheck'
}

export function actionHandleApi(uri, dataBase, key = 'Repair', isDemoUser) {
  console.log(`key`, key)
  if (['Quick Check', 'Repair'].includes(key)) {
    const { deviceId } = dataBase
    return fetchRequest({
      baseURL: isDemoUser ? `${API_DEVICE_OPERATOR}/demo` : API_DHC,
      url: `${uri}/${deviceId}`,
      method: 'post',
      data: dataBase
    })
  }

  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: uri,
    method: 'post',
    data: dataBase
  })
}
/*Device Actions Api End*/

/**
 * @Desc 批量升级
 * @param installInfo
 * @returns {AxiosPromise}
 */
export function updateAll(installInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/operator/deviceInstallUpgradeLists',
    method: 'post',
    data: installInfo
  })
}

/**
 * @Desc 批量重启 暂时废弃
 * @param deviceInfo
 * @returns {AxiosPromise}
 */
export function restartSystemAll(deviceInfo) {
  return fetchRequest({
    url: '/masterdeviceinfoconfig/deviceRestart',
    method: 'post',
    data: deviceInfo
  })
}

/**
 * @Desc 升级当前设备
 * @param deviceInfo
 * @returns {AxiosPromise}
 */
export function deviceUpgrade(deviceInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/operator/upgrade',
    method: 'post',
    data: deviceInfo
  })
}
/**
 * @Desc 获取个人设备列表
 * @param dataBase
 * @returns {AxiosPromise}
 */
export function getPersonalDeviceLists(dataBase = {}) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/deviceBaseInfo/personalDeviceList',
    method: 'post',
    data: dataBase
  })
}

/**
 * @Desc 获取account 用户列表
 * @param paramInfo
 * @returns {*} userStatus 0 正常 1 冻结
 */
export function getAccountUserList(paramInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/account/getAccountUserList',
    method: 'get',
    params: paramInfo
  })
}

/**
 * @description: 获取account 用户列表 只给assin to user使用
 * 全部都是正常用户，不支持搜索
 * @param {*} paramInfo
 * @return {*}
 */
export function getAccountUserListByAssignToUser(paramInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/account/getAccountMemberListAuto',
    method: 'get',
    params: paramInfo
  })
}

/**
 * @Desc 更新用户信息
 * @param updateInfo
 * @returns {AxiosPromise}
 */
export function updateAccount(updateInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/account/updateAccount',
    method: 'post',
    data: updateInfo
  })
}

/**
 * @Desc: 更新Personal Profile信息
 * @param dataInfo
 * @returns {AxiosPromise}
 */
export function personalProfileUpdate(dataInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: 'user/v2/update',
    method: 'post',
    data: dataInfo
  })
}

/**
 * @Desc 获取角色权限列表
 * @param paramInfo
 * @returns {*}
 */
export function getCountUserInRole(paramInfo = {}) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/roleinfo/countUserInRole',
    method: 'get',
    params: paramInfo
  })
}

/**
 * @Desc 邀请用户
 * @param userList
 * @returns {AxiosPromise}
 */
export function inviteUsers(userList) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/userInvite/inviteUsers',
    method: 'post',
    data: userList
  })
}

/**
 * @Desc 获取用户所属公司下的邀请等待列表
 * @param query
 * @returns {AxiosPromise}
 */
export function getInvitePendingList(query) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/userInvite/queryPage',
    method: 'get',
    params: query
  })
}

/**
 * @Desc 用户邀请-邀请用户重发邮件
 * @param inviteInfo
 * @returns {AxiosPromise}
 */
export function resendInviteEmail(inviteInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/userInvite/resendEmail',
    method: 'post',
    data: inviteInfo
  })
}

/**
 * @Desc 用户邀请-撤销邀请
 * @param inviteInfo
 * @returns {AxiosPromise}
 */
export function remokeInviteEmail(inviteInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/userInvite/revoke',
    method: 'post',
    data: inviteInfo
    // headers: { 'Content-Type':'application/x-www-form-urlencoded'}
  })
}

/**
 * @Desc 邀请已注册orbit用户
 * @param userInfo
 * @returns {AxiosPromise}
 */
export function addUsersToCompany(userInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/userInvite/addToCompany',
    method: 'post',
    data: userInfo
  })
}

/**
 * @Desc 获取Settings页面配置信息
 * @param paramInfo
 * @returns {AxiosPromise}
 */
export function getDeviceSettingLists(paramInfo) {
  return fetchRequest({
    baseURL: API_ORBIT_SETTING_SERVICE,
    url: '/v1/device/settings',
    method: 'get',
    params: paramInfo
  })
}

/**
 * @Desc setting页面请求
 * @param uri
 * @param dataInfo
 * @returns {*}
 */
export function settingsHandle(uri, dataInfo) {
  return fetchRequest({
    url: uri,
    method: 'post',
    data: dataInfo
  })
}

/**
 * @Desc setting 页面 updateTripleCamera / inclusiView
 * @param uri
 * @param dataInfo
 * @returns {*}
 */
export function updateTripleCamera(params) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: 'operator/updateTripleCamera',
    method: 'get',
    params: params
  })
}

/**
 * @Desc 邀请未注册用户、注册DTEN账号
 * @param registerInfo
 * @param other
 * @returns {*}
 */
export function invitationToRegister(registerInfo, other) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/userInvite/addToCompanyWithRegister',
    method: 'post',
    data: { ...mapObjectValues(registerInfo), ...other }
  })
}

/**
 * @Desc 检查用户是否为orbit用户
 * @param emailAddress
 * @returns {AxiosPromise}
 */
export function checkUserExist(emailAddress) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/user/checkUserExist',
    method: 'post',
    data: emailAddress
  })
}

/**
 * @Desc activation模块重置密码
 * @param email
 * @param site activation （普通忘记密码) ｜ activationReEnter(reenter 重置密码)
 * @returns {AxiosPromise}
 */
export function sendResetPassword(email, site = 'activation') {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/catch/sendresetpassword',
    method: 'post',
    data: { email: email, scenario: site }
  })
}

/**
 * @description: 根据deviceCompanyId 获取设备列表信息
 * @param {*}
 * @return {*}
 */
export function getDeviceListByCompanyId(dataInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/deviceBaseInfo/getDeviceListByCompanyId',
    method: 'post',
    data: dataInfo
  })
}

/**
 * @description: 获取License列表
 * @param {*}
 * @return {*}
 */
export function getLicenseList(dataInfo) {
  return fetchRequest({
    url: '/plan/license/query',
    method: 'post',
    data: dataInfo
  })
}

/**
 * @description: 获取License列表 分页
 * @param {*}
 * @return {*}
 */
export function getLicenseListByPage(dataInfo) {
  return fetchRequest({
    url: '/plan/license/queryByPage',
    method: 'post',
    data: dataInfo
  })
}

/**
 * @description: 获取License总个数
 * @param {*}
 * @return {*}
 */
export function getAvailableNum(dataInfo) {
  return fetchRequest({
    url: '/plan/license/queryAvailableNum',
    method: 'post',
    data: dataInfo
  })
}

/**
 * @description: assignToDevice
 * @param {*}
 * @return {*}
 */
export function assignToDevice(dataInfo) {
  return fetchRequest({
    url: '/plan/license/assignToDevice',
    method: 'post',
    data: dataInfo
  })
}

/**
 * @Desc get administrators lists
 * @returns {AxiosPromise}
 */
export function getAccountAdminList() {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    // url: '/account/getAccountAdminList',
    url: '/account/getAccountAdminListAuto',
    method: 'get'
  })
}

/**
 * @Desc Get the list of available mailboxes under the current account
 * @param query
 * @returns {AxiosPromise}
 */
export function getAccountMemberList(paramsInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/account/getAccountMemberList',
    method: 'get',
    params: paramsInfo
  })
}

export function updateProxy(proxyInfo) {
  return fetchRequest({
    url: '/devicebaseinfo/updateProxy',
    method: 'post',
    data: proxyInfo
  })
}

/**
 * @Desc TimeZone Lists Api
 * @param paramsInfo
 * @returns {AxiosPromise}
 */
export function getTimeZoneLists() {
  return fetchRequest({
    url: '/timezone/loadTimezones',
    method: 'get'
  })
}

/**
 * @Desc Get the timezone corresponding to the current device
 * @param deviceTimezone
 * @returns {AxiosPromise}
 */
export function getDeviceTimeZone(deviceTimezone) {
  return fetchRequest({
    url: `/timezone/${deviceTimezone}`,
    method: 'get'
  })
}

/**
 * @Desc update device timezone
 * @param timezoneInfo
 * @returns {AxiosPromise}
 */
export function updateDeviceTimezone(timezoneInfo) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/operator/updateDeviceTimezone',
    method: 'post',
    data: timezoneInfo
  })
}

/**
 * @Desc Switch the current Wi-Fi
 * @param wifiInfo
 * @returns {AxiosPromise}
 */
export function updateCurrentWifi(wifiInfo) {
  return fetchRequest({
    url: '/devicebaseinfo/updateWifi',
    method: 'post',
    data: wifiInfo
  })
}

/**
 * @description: 设置NTP Server
 * @param {*}
 * @return {*}
 */
export function updateNtpSetting(params) {
  return fetchRequest({
    url: '/devicebaseinfo/updateNtpSetting',
    method: 'post',
    data: params
  })
}

/**
 * @description: 设置Device passcode
 * @param {*}
 * @return {*}
 */
export function updatePasscodeSetting(params) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/operator/updateDeviceLock',
    method: 'post',
    data: params
  })
}

/**
 * @description:  更新设备端Ethernet
 * @param {*}
 * @return {*}
 */
export function updateEthernet(params) {
  return fetchRequest({
    url: '/devicebaseinfo/updateEthernet',
    method: 'post',
    data: params
  })
}

/**
 * @description: 获取Warranty
 * @param {*}
 * @return {*}
 */
export function getWarranty(params) {
  return fetchRequest({
    url: '/devicebaseinfo/getWarranty',
    method: 'get',
    params: params
  })
}

/**
 * @description: 获取Warranty,Ip location
 * @param {*}
 * @return {*}
 */
export function getDeviceLazyInfo(params) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/deviceBaseInfo/getDeviceLazyInfo',
    method: 'get',
    params: params
  })
}

/**
 * @description: 更新802证书设置
 * https://api-doc.displayten.com.cn/project/194/interface/api/3815
 * @param {*} params
 * @return {*}
 */
export function updateCertificate(params) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/operator/updateCertificate',
    method: 'post',
    data: params
  })
}

/**
 * @description: 设置属性开关（批量）
 * @param {*} params
 * @return {*}
 */
export function batchUpdateSettingLock(params) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/operator/batchUpdateSettingLock',
    method: 'post',
    data: params
  })
}

/**
 * @Desc add administrators api
 * @param lists
 * @returns {AxiosPromise}
 */
export function addRoleByAccountNum(lists) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/account/addRoleByAccountNum',
    method: 'post',
    data: lists
  })
}

/**
 * @Desc Removes current user from administrator list
 * @param userId
 * @returns {Promise}
 */
export function removeRoleAccount(userId) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/account/removeRoleByAccountNum',
    method: 'post',
    data: {
      userIds: userId
    }
  })
}

/**
 * @description: 账号冻结
 * @param {*}
 * @return {*}
 */
export function deactivateUser(userId) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/user/freezing',
    method: 'post',
    data: {
      userId: userId
    }
  })
}

/**
 * @description: 删除账号
 * @param {*}
 * @return {*}
 */
export function deleteUser(userId) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: `/user/${userId}`,
    method: 'delete'
  })
}

/**
 * @description: 账号解冻
 * @param {*}
 * @return {*}
 */
export function activateUser(userId) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/user/unFreezing',
    method: 'post',
    data: {
      userId: userId
    }
  })
}

/**
 * @description: assign device to user
 * @param {*}
 * fromEmailAddress 原设备拥有人邮件地址
 * toEmailAddress 新设备拥有人邮件地址
 * did
 * removeFlag
 * @return {*}
 */
export function assignDevice(params) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/deviceuserbind/assignDevice',
    method: 'post',
    data: params
  })
}

/**
 * @Desc 获取OIDC设置信息
 * @param queryInfo
 * @returns { Promise }
 */
export function getOIDCSetting(queryInfo) {
  // return fetchRequest({
  //   url: '/oauth/oidc/setting',
  //   method: 'get',
  //   params: queryInfo
  // })
  return fetchRequest({
    baseURL: API_SECURITY_SERVICE,
    url: `/v1/sso/authn-init`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-App-Code': 'ORBIT'
    },
    params: queryInfo
  })
}

/**
 * @Desc get current company domain
 * @returns { Promise }
 */
export function queryCompanyDomain() {
  return fetchRequest({
    url: '/oauth/account/state',
    // 1.21版本后，支持put
    // url: '/oauth/account/state',
    method: 'get'
  })
}

/**
 * @Desc save or update OID setting
 * @param settingInfo
 * @returns { Promise }
 */

export function saveOrUpdateOIDCSetting(settingInfo, idp) {
  return fetchRequest({
    url: `/oauth/oidc/setting?idp=${idp}`,
    method: 'post',
    data: settingInfo,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function getSSOSetting(domainName) {
  console.log('get SSO Setting by domain:', domainName)
  return fetchRequest({
    baseURL: API_SECURITY_SERVICE,
    url: `/v1/sso/setting?domainName=${domainName}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-App-Code': 'ORBIT'
    }
  })
}

export function saveOssSamlSetting(data) {
  return fetchRequest({
    url: '/v1/sso/saml2/setting',
    baseURL: API_SECURITY_SERVICE,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-App-Code': 'ORBIT'
    },
    data: data
  })
}

export function saveOssSamlFile(data) {
  return fetchRequest({
    url: '/v1/sso/saml2/metadata',
    baseURL: API_SECURITY_SERVICE,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-App-Code': 'ORBIT'
    },
    data: data
  })
}

/**
 * @Desc update account domain
 * @param domainInfo
 * @returns { Promise }
 */
export function updateAccountDomain(domainInfo) {
  return fetchRequest({
    url: '/oauth/account/domain',
    method: 'put',
    // 1.21之后的版本再改为put
    // method: 'put',
    data: domainInfo
  })
}

/**
 * @Desc get sso switch status
 * @returns { Promise }
 */
export function getSsoSwitchStatus() {
  return fetchRequest({
    url: '/oauth/account/sso-switch',
    method: 'get'
  })
}

/**
 * @Desc save or update sso switch
 * @param switchInfo switch
 * @returns { Promise }
 */
export function saveOrUpdateSSOSwitch(switchInfo, switchEnable) {
  return fetchRequest({
    url: `/oauth/account/sso-switch/${switchEnable}`,
    method: 'put',
    data: switchInfo
  })
}

/**
 * @Desc Gets the number of active licenses
 * @param id
 * @returns { Promise }
 */
export function queryActiveNum(id) {
  return fetchRequest({
    url: '/plan/license/queryActiveNum',
    method: 'post',
    data: { companyId: id }
  })
}

export function uploadSingle(data, onUploadProgress) {
  return fetchRequest({
    url: '/upload/singleFile',
    timeout: UPLOADTIMEOUT,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  })
}

/**
 *  上传 BYOD 图片
 * @param data
 * @param onUploadProgress
 * @returns {*}
 */
export function uploadByodImg(data, onUploadProgress) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/upload/byod-bg-img',
    timeout: UPLOADTIMEOUT,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  })
}

/**
 *  确认上传的背景图片
 * @param fileName
 * @returns {*}
 */
export function confirmByodImg(fileName) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: `/deviceselftchecktimingconfig/updateByodBgUrl?fileName=${fileName}`,
    method: 'get'
  })
}

/**
 * 重置 BYOD 图片位空
 * @returns {*}
 */
export function resetByodImg() {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: `/deviceselftchecktimingconfig/clearByodBgUrl`,
    method: 'post'
  })
}

/**
 * 设置默认 Volume
 */
export function setDefaultVolume(volume) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: `/deviceselftchecktimingconfig/defaultVolume?volume=${volume}`,
    method: 'put'
  })
}

/**
 * @Desc 获取设备列表
 * @param {*}
 * @return {*}
 */
export function getSimplestDeviceList() {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/deviceBaseInfo/getSimplestDeviceList',
    method: 'get'
  })
}

/**
 * @Desc（图片，视频，文件）上传
 * @param {*}
 * @return {*}
 */
export function UploadFile(data) {
  return fetchRequest({
    baseURL: API_SUPPORT,
    url: '/tickets/upload',
    timeout: UPLOADTIMEOUT,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: data
  })
}

/**
 * @Desc create tickets
 * @param {*}
 * @return {*}
 */
export function createTickets(params) {
  return fetchRequest({
    baseURL: API_SUPPORT,
    url: '/tickets/create',
    method: 'post',
    data: params
  })
}

/**
 * @Desc create tickets
 * @param {*}
 * @return {*}
 */
export function getTickets(params) {
  return fetchRequest({
    baseURL: API_SUPPORT,
    url: '/tickets/list',
    method: 'get',
    params: params
  })
}

/**
 * @Desc get last reply name and time
 * @param {*}
 * @return {*}
 */
export function getLastReply(params) {
  return fetchRequest({
    baseURL: API_SUPPORT,
    url: '/tickets/getLastReply',
    method: 'get',
    params: params
  })
}

/**
 * @Desc create comment
 * @param {*}
 * @return {*}
 */
export function createTicketComment(params) {
  return fetchRequest({
    baseURL: API_SUPPORT,
    url: '/comment/create',
    method: 'post',
    data: params
  })
}

/**
 * @Desc get commnent
 * @param {*}
 * @return {*}
 */
export function getTicketCommentList(params) {
  return fetchRequest({
    baseURL: API_SUPPORT,
    url: '/comment/list',
    method: 'get',
    params: params
  })
}

/**
 * @Desc get ticket detail
 * @param {*}
 * @return {*}
 */
export function getTicketDetail(id, params) {
  return fetchRequest({
    baseURL: API_SUPPORT,
    url: `/tickets/${id}`,
    method: 'get',
    params: params
  })
}

/**
 * @Desc update ticket status
 * @param {*}
 * @return {*}
 */
export function updateTicketStatus(data) {
  return fetchRequest({
    baseURL: API_SUPPORT,
    url: '/tickets/update',
    method: 'post',
    data
  })
}

/**
 * @Desc thumbs-up thumbs-down
 * @param {*}
 * @return {*}
 */
export function clickStar(data) {
  return fetchRequest({
    baseURL: API_SUPPORT,
    url: '/tickets/clickStar',
    method: 'post',
    data
  })
}

/**
 * @Desc get star
 * @param {*}
 * @return {*}
 */
export function getStar(params) {
  return fetchRequest({
    baseURL: API_SUPPORT,
    url: '/tickets/getStar',
    method: 'get',
    params: params
  })
}

/**
 * @description:  dashboard 设备总数 在线设备数 离线设备数 忙碌设备数 空闲设备数
 * @param {*}
 * @return {*}
 * {
 * totalNum    设备总数
 * onlineNum   在线设备数
 * offlineNum  离线设备数
 * busyNum     忙碌设备数
 * freeNum     空闲设备数
 * }
 */
export function getOnlineOffline(params) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/statistics/onlineOffline',
    method: 'get',
    params: params
  })
}

/**
 * @description: 设备健康状态统计
 * @param {*}
 * @return {*}
 * {
 * basicPlanNum     basic plan设备数
 * healthNum        健康设备数
 * issueNum         issue设备数
 * lastCheckTime    最后一次自检时间
 * premiumPlanNum   premium Plan设备数
 * proPlanNum       pro plan设备数
 * totalNum         总设备数
 * uncheckedNum     unchecked设备数
 * }
 *
 *
 *
 */
export function getHealthStatus(params) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/statistics/healthStatus',
    method: 'get',
    params: params
  })
}
/**
 * @description: dashboard ToDoList issue
 * @param {*}
 * @return {*}
 */
export function getToDoListIssues() {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/deviceBaseInfo/loadDeviceWithIssues',
    method: 'get'
  })
}

/**
 * @description: dashboard ToDoList unchecked
 * @param {*}
 * @return {*}
 */
export function getToDoListUnchecked() {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/deviceBaseInfo/loadDeviceWithUnchecked',
    method: 'get'
  })
}

export function getToDoListAwaitingUpdate() {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/deviceBaseInfo/getUpgradeDeviceList',
    method: 'get'
  })
}

/**
 * @description: 批量自检 废弃
 * @param {*} ids 1674,1234,3457
 * @return {*}
 */
export function batchCheck_discard(params) {
  const { ids } = params
  return fetchRequest({
    baseURL: API_DHC,
    // url: '/operator/batchCheck',
    url: `/self-checks/device-ids/?deviceIds=${ids}`,
    method: 'post'
  })
}

/**
 * @description: 批量自检
 * @param {*} ids 1674,1234,3457
 * @return {*}
 */
export function batchCheck(params) {
  return fetchRequest({
    baseURL: API_DHC,
    // url: '/operator/batchCheck',
    url: `/self-checks/batchCheck`,
    method: 'post',
    data: params,
    // params: ids,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * @description: 批量自检
 * @param {*} ids 1674,1234,3457
 * @return {*}
 */
export function batchCheckSaleDemo(params) {
  return fetchRequest({
    baseURL: `${API_DEVICE_OPERATOR}/demo`,
    url: `/operator/batchCheck`,
    method: 'post',
    data: params,
    // params: ids,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * @description:
 * @param {*} params ids 1674,1234,3457
 * @return {*}
 */
export function batchRepair_discard(params, idsParams) {
  const { ids } = idsParams
  return fetchRequest({
    baseURL: API_DHC,
    url: `/repairs/device-ids?deviceIds=${ids}`,
    method: 'post',
    data: params,
    // params: ids,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * @description:
 * @param {*} params ids 1674,1234,3457
 * @return {*}
 */
export function batchRepair(params) {
  return fetchRequest({
    baseURL: API_DHC,
    url: `/repairs/batchRepair`,
    method: 'post',
    data: params,
    // params: ids,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * @description: 销售demo 批量修复
 * @param {*} params ids 1674,1234,3457
 * @return {*}
 */
export function batchRepairSaleDemo(params) {
  return fetchRequest({
    baseURL: `${API_DEVICE_OPERATOR}/demo`,
    url: `/operator/batchRepair`,
    method: 'post',
    data: params,
    // params: ids,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * @description: Clear all  废弃
 * @param {*} params ids 1674,1234,3457
 * @return {*}
 */
export function batchCloseAllOffline(data) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/offlineMsg/deleteCommands',
    method: 'post',
    data
  })
}

export function batchCloseAllOfflinePlus(data) {
  return fetchRequest({
    timeout: TOO_LONG_TIMEOUT,
    baseURL: API_DEVICE_MANAGER,
    url: '/offlineMsg/batchDeleteCmd',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: clear completed 废弃
 * @param {*} params ids 1674,1234,3457
 * @return {*}
 */
export function batchCompleteAllOffline(data) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/offlineMsg/deleteCompleteCommands',
    method: 'post',
    data
  })
}

export function batchCompleteAllOfflinePlus(data) {
  return fetchRequest({
    timeout: TOO_LONG_TIMEOUT,
    baseURL: API_DEVICE_MANAGER,
    url: '/offlineMsg/batchDeleteCompleteCmd',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: 单条离线指令删除
 * @param {*}
 * @return {*}
 */
export function deleteOfflineIns(data) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/offlineMsg/deleteCommand',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: Top 10 devices with lower usage
 * @param {*} params
 * @return {*}
 */
export function top10Devices(params) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/statistics/top10/devices',
    method: 'get',
    params
  })
}

/**
 * @description: devices with lower usage
 * @param {*}
 * @return {*}
 */
export function getUsageList(params) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/statistics/list/devices',
    method: 'get',
    params
  })
}

export function updateOwner(data) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: `/account/owner/change`,
    method: 'post',
    data
  })
}

export function loadOfflineList(params) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: `/offlineMsg/getOfflineCommands`,
    method: 'get',
    params
  })
}

/**
 * @description: 获取问卷是否已提交过
 * @param {params} params
 * activityType=bluet_invite
 * @return {AxiosPromise}
 */
export function loadQuestionStatus(params) {
  return fetchRequest({
    baseURL: API_SURVEY,
    url: `/record/getFeedbackStatus`,
    method: 'get',
    params
  })
}

/**
 * @description: 获取问卷题目列表
 * @param {params} params
 * type=bluet_feedback
 * @return {AxiosPromise}
 */
export function loadQuestionList(params) {
  return fetchRequest({
    baseURL: API_SURVEY,
    url: `/surveyQuestion/getQuestionNumberList`,
    method: 'get',
    params
  })
}

/**
 * @description: 提交问卷答案 废弃
 * @param {*} data
 *  activityId: 1,
 *  surveyAnswer: answers
 * @return {*}
 */
export function addAnswerQuestion(data) {
  return fetchRequest({
    baseURL: API_SURVEY,
    url: `/surveyResult/addUserSurveyResult`,
    method: 'post',
    data
  })
}

// /marketing-service/v1/api/feedback/answer

/**
 * @description: 提交问卷答案
 * @param {*} data
{
  "itemId": "SXFLMDNnTnVMV2RGazhCYzFGbUtFNTJqRUNBbHVzVWY=",
  "answers": {
    "1": "I agree",
    "3": "satisfaction",
    "5": "it's ok"
  }
}
 * @return {*}
 */
export function addAnswerQuestionNew(data) {
  return fetchRequest({
    baseURL: API_MARKET,
    url: `/v1/feedback/answer`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
      'X-App-Code': 'MARKETING'
    }
  })
}

/**
 * @description: onboard beta提交问卷 不需要登录
 * @param {*} data
 * @return {*}
 */
export function addAnswerQuestionNewAnonymous(data) {
  return fetchRequest({
    baseURL: API_MARKET,
    url: `/v1/feedback/anonymous/answer`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
      'X-App-Code': 'MARKETING'
    }
  })
}

/**
 * @description: app 升级 作废
 * @param {*} data
 * deviceId
 * appId
 * versionName
 * versionCode
 * @return {*}
 */
export function updateApp(data) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: `/appupdate/update`,
    method: 'post',
    data
  })
}

/**
 * @description: 批量升级app 作废
 * @param {*} data
 * @return {*}
 */
export function updateAppBatch(data) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: `/appupdate/batchUpdate`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * @description: d7 help updateNow, go mate updateNow
 * @param {*} data
 * @return {*}
 */
export function acceptBeta(data) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: `/beta-version/accept-beta-invitation`,
    method: 'post',
    data
  })
}

/**
 * @description: health code
 * @param {*} userInfo
 * @return {*}
 */
export function getHealthCodes(params) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/errorDefinition/getAllError',
    method: 'get',
    params
  })
}

/**
 * @description: health code
 * @param {*} userInfo
 * @return {*}
 */
export function getHealthCodeById(params) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: `/errorDefinition/getSingleError`,
    method: 'get',
    params
  })
}

/**
 * @description: help页面 可升级设备列表
 * @param {*} userInfo
 * @return {*}
 */
export function loadHelpDeviceList(data) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: `/deviceBaseInfo/getCurrentUserDeviceList`,
    method: 'post',
    data
  })
}

/**
 * @description: okTa 自动登录orbit
 * @param {*} data
 * @return {*}
 */
export function oktaAuto(params) {
  return fetchRequest({
    baseURL: API_SECURITY_SERVICE,
    url: `/v2/oidc/setting`,
    method: 'get',
    params,
    headers: {
      'X-App-Code': 'ORBIT'
    }
  })
}

/**
 * @description: dashboard 应用升级列表
 * @param {*}
 * @return {*}
 */
export const loadAppUpgradeList = params => {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: `/deviceBaseInfo/getAppUpgradeList`,
    method: 'get',
    params
  })
}

/**
 * @description:  扫二维码激活绑定
 * @param {*} params
 * flowId
 * timezone
 * @return {*}
 */
export function activationBindByScan(params) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/qractivation/bind',
    method: 'post',
    params
  })
}

/**
 * @description: 未注册用户二维码扫码激活邮件发送
 * @param {*} params
 * @return {*}
 */
export function sendRegEmailByScan(data) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/qractivation/sendRegeditEmail',
    method: 'post',
    data
  })
}

/**
 * @description: 未注册二维码扫码用户补充信息并激活设备
 * @param {*} params
 * flowId
 * userName
 * nickname
 * password
 * timezone
 * @return {*}
 */
export function replenishUserInfoByScan(data) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/qractivation/regeditAndActivation',
    method: 'post',
    data
  })
}

/**
 * @description: 忘记密码 二维码扫码 发送邮件
 * @param {*} params
 * email
 * flowId
 * @return {*}
 */
export function sendResetPasswordEmailByScan(data) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/qractivation/qrSendResetPasswordEmail',
    method: 'post',
    data
  })
}

/**
 * @description: 重置密码 二维码扫码
 * @param {*} params
 * key
 * password
 * @return {*}
 */
export function resetPasswordByScan(data) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/qractivation/qrResetNewPassword',
    method: 'post',
    data
  })
}

/**
 * @description: windows 补丁列表
 * @param {*}
 * @return {*}
 */

export function loadFetchList(data) {
  return fetchRequest({
    baseURL: API_DEVICE_PATCH_SERVICE,
    url: '/patch/getPatchList',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function loadUninstallDeviceList(data) {
  return fetchRequest({
    baseURL: API_DEVICE_PATCH_SERVICE,
    url: '/patch/getUninstallDeviceList',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description:  批量升级windows 补丁
 * @param {*} data
 * @return {*}
 */
export function assignPatchService(data) {
  return fetchRequest({
    baseURL: API_DEVICE_PATCH_SERVICE,
    url: '/patch/assignPatch',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function loadInstallingDeviceList(data) {
  return fetchRequest({
    baseURL: API_DEVICE_PATCH_SERVICE,
    url: '/patch/getInstallingDeviceList',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: 单设备app升级
 * @param {*}
 * @return {*}
 */
export function upgradeAppFetch(data) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/appupdate/update',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * @description: 批量升级,多个设备App升级(go + mate 算2个设备)
 * @param {*} data
 * @return {*}
 */
export function upgradeBatchAppsFetch(data) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/appupdate/batchUpdate',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * @description: 修改邮件通知管理员的开关
 * @param {*} data
 * @return {*}
 */
export function updateEmailAlerts(data) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/deviceselftchecktimingconfig/updateEmailAlerts',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data
  })
}

/**
 * @description: 修改go&mate的switch mode,取值为 mirror|zrc
 * @param {*} data
 * @return {*}
 */
export function updateMateMode(data) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/deviceBaseInfo/changeMateMode',
    method: 'post',
    data
  })
}

/**
 * @description: 修改 resolution, 1,表示设置1080p，4，表示4k
 * https://api-doc.displayten.com.cn/project/194/interface/api/1497
 * @param {*} data
 * @return {*}
 */
export function updateStatus4Mqtt(data) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/operator/updateStatus4Mqtt',
    method: 'post',
    data
  })
}

/**
 * @description: zendesk 重置密码 发送6位数字验证码
 *
 * @param {*} data
 * @return {*}
 */
export function sendNumberCode(params) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/verifyCode/sendCode',
    method: 'get',
    params
  })
}

/**
 * @description:  zendesk 重置密码
 * @param {*} data
 * @return {*}
 */
export function resetPasswordByNumberCode(data) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/resetPasswordByVerifyCode',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data
  })
}

/**
 * @Desc get ticket detail
 * @param {*}
 * @return {*}
 */
export function getTicketOptions(id, params) {
  return fetchRequest({
    baseURL: API_SUPPORT,
    url: `/tickets/getSubDefault`,
    method: 'get',
    params: params
  })
}

export function getLastOneTicket(id, params) {
  return fetchRequest({
    baseURL: API_SUPPORT,
    url: `/tickets/getLastRequest`,
    method: 'get',
    params: params
  })
}

/**
 * @param queryBase
 * @returns {AxiosPromise}
 * @Desc 获取上下线日志
 */

export function getDeviceStatusLists(params) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/deviceStatus/list',
    method: 'get',
    params
  })
}

/**
 * @description: dashboard patch 列表
 * @param {*}
 * @return {*}
 */
export const loadPatchAwaitingInstallList = params => {
  return fetchRequest({
    baseURL: API_DEVICE_PATCH_SERVICE,
    url: `/patch/getDevicePatchList`,
    method: 'get',
    params
  })
}

/**
 * @description: device list 列表 导出 excel
 * @param {*}
 * @return {*}
 */
export const exportDeviceListApi = params => {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: `/deviceBaseInfo/exportDeviceList`,
    method: 'get',
    params,
    responseType: 'blob'
  })
}

/**
 * @description: orbit菜单查询授权列表接口
 * @param {*} params
 * @return {*}
 */
export const loadPermissionsList = params => {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: `/ctrlperm/permissions/getPermissionsByAccount`,
    method: 'get',
    params
  })
}

/**
 * @description: orbit 激活是否成功
 * @param {*} params
 * @return {*}
 */
export const loadPermissionsDetail = params => {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: `/ctrlperm/permissions/detail`,
    method: 'get',
    params
  })
}

/**
 * @description: orbit拒绝授权
 * @param {*} params
 * @return {*}
 */
export const refusesApi = data => {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: `/ctrlperm/permissions/refuses`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: orbit同意授权
 * @param {*} params
 * @return {*}
 */
export const approvalApi = data => {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: `/ctrlperm/permissions/approval`,
    method: 'patch',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: 通过4位激活码授权
 * @param {*} params
 * @return {*}
 */
export const approvalBy4CodeApi = data => {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: `/ctrlperm/permissions/approvalBy4Code`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: 批量重启设备
 * @param {*} data
 * @return {*}
 */
export const restartBatchApi = data => {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: `/operator/deviceBatchRestart`,
    method: 'post',
    data
  })
}

export const getSixDigitActiveCodeApi = params => {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/activationCode/getSixDigitActiveCode4Approval',
    method: 'get',
    params
  })
}

/**
 * @description: 销售 sales 重置数据
 * @param {*} data
 * @return {*}
 */
export const salesDemoRestoreApi = data => {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/sales/demoResume',
    method: 'post',
    data
  })
}
/**
 * @description: 销售 sales reset agreement
 * @param {*} data
 * @return {*}
 */
export const salesDemoResetAgreementApi = data => {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/sales/demo/contracts',
    method: 'delete',
    data
  })
}
/**
 * @description: 账号是否有D7设备
 * @param {*} params
 * @return {*}
 */
export const getHasD7Api = params => {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/deviceBaseInfo/hasD7Device',
    method: 'get',
    params
  })
}
/**
 * @description: mission control 活动 list
 * @param {*} params
 * @return {*}
 */
export const getMissionControlDeviceListApi = params => {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/betaInfo/getDeviceList',
    method: 'get',
    params
  })
}

export const approveMissionControlApi = data => {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/betaInfo/requestBetaPermission',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: 判断菜单是否显示
 * @param {*} data
 * @return {*}
 */
export const getMenuShowApi = params => {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/betaInfo/getMenuSetting',
    method: 'get',
    params
  })
}

/**
 * @description:
 * @param {*} data
 * @return {*}
 */
export const getWorkInfoApi = params => {
  return fetchRequest({
    baseURL: API_SUPPORT,
    url: '/tickets/getWorkInfo',
    method: 'get',
    params
  })
}

/**
 * @description: 刷新pinCode
 * @param {*} data
 * @return {*}
 */
export const flushPinCodeApi = data => {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: `/operator/flushPinCode`,
    method: 'post',
    data
  })
}

/**
 * @description: 刷新group pinCode
 * @param {*} data
 * @return {*}
 */
export const flushGroupPinCodeApi = data => {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: `/company-setting/pinconf/pincode`,
    method: 'put',
    data
  })
}

/**
 * @description: Enable or Disable Group Lock Dashboard Pin Code
 * @param {*} data
 * @return {*}
 */
export const setGroupPinCodeStatus = code => {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: `/company-setting/pinconf?tenantLevel=${code}`,
    method: 'post'
  })
}

/**
 * @description: Edit pinCode
 * @param {*} data
 * @return {*}
 */
export const manuallySetGroupPincode = code => {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: `/company-setting/pinconf?pinCode=${code}`,
    method: 'post'
  })
}

/**
 * @description: Edit pinCode
 * @param {*} data
 * @return {*}
 */
export const manuallySetDevicePincode = (deviceId, pinCode) => {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: `/operator/updatePinCode?deviceId=${deviceId}&pinCode=${pinCode}`,
    method: 'post'
  })
}

/**
 * @description: device group list
 * @param {*} data
 * @return {*}
 */
export const loadDeviceGroupApi = data => {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: `/devicegroup/list`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: device group add
 * @param {*} data
 * @return {*}
 */
export const addDeviceGroupApi = data => {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: `/devicegroup/add`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: device group update
 * @param {*} data
 * @return {*}
 */
export const updateDeviceGroupApi = data => {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: `/devicegroup/upd`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: device group delete
 * @param {*} data
 * @return {*}
 */
export const deleteDeviceGroupApi = data => {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: `/devicegroup/del`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: assignDevice2Group
 * @param {*} data
 * @return {*}
 */
export const assignDevice2GroupApi = data => {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: `/devicegroup/assignDevice2Group`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: assignDevice2Group
 * @param {*} data
 * @return {*}
 */
export const loadGroupInfoApi = data => {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: `/devicegroup/query`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @Desc 获取全局设置 powerSaving
 * @param queryInfo
 * @returns {AxiosPromise}
 */
export function updateSavePowerApi(data) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/deviceselftchecktimingconfig/updateSavePowerConfig',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @Desc 专门设置，设备（D7 002） powerSaving
 * @param queryInfo
 * @returns {AxiosPromise}
 */
export function updatePowerSaveConfig(data, deviceId) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: `/deviceBaseInfo/updatePowerSaveConfig?deviceId=${deviceId}`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: Customer Group 列表
 * @param {*} queryBase
 * @return {*}
 */
export function loadCustomerGroupListApi(params) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/serviceContract/getServiceContractByCustomer',
    method: 'get',
    params
  })
}

/**
 * @Desc: 切换 customer group
 * @param userKey
 * @returns {AxiosPromise}
 */
export function changeProxyApi(data) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: '/user/choiceProxy',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: 获取权限全集
 * @param {*} params
 * @return {*}
 */
export function getAuthoritiesApi(params) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/serviceContract/getAuthorities',
    method: 'get',
    params,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: 合同详情（customer group 详情）
 * 获取该customer的权限，预览
 * @param {*} params contractId
 * @return {*}
 */
export function getContractApi(params) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/serviceContract/contract',
    method: 'get',
    params,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: 服务记录查询-custom
 * @param {*} data
 * @return {*}
 */
export function loadActivityCustomerApi(data) {
  return fetchRequest({
    baseURL: API_SIEM,
    url: '/events/customer/list',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: 服务记录查询-partner
 * @param {*} data
 * @return {*}
 */
export function loadActivityPartnerApi(data) {
  return fetchRequest({
    baseURL: API_SIEM,
    url: '/events/customer/list',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: customer 获取自己的合作伙伴信息 Partner Account Profile
 * @param {*} params
 * @return {*}
 */
export function loadPartnerInfoApi(params) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/serviceContract/getPartnerInfo',
    method: 'get',
    params,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: 合作伙伴 根据customerId 获取用户权限
 * @param {*} params customerId
 * @return {*}
 */
export function loadPartnerPermissionsApi(params) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/serviceContract/getPartnerInfoByCustomer',
    method: 'get',
    params,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: partner管理协议
 * https://api-doc.displayten.com.cn/project/81/interface/api/3520
 * @param {*} params
 * @return {*}
 */
export function loadAgreementByPartnerApi(params) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/serviceContractHelper/getServiceContractByPartner',
    method: 'get',
    params,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: customer 管理协议
 * https://api-doc.displayten.com.cn/project/81/interface/api/3524
 * @param {*} params
 * @return {*}
 */
export function loadAgreementByCustomerApi(params) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/serviceContractHelper/getServiceContractByCustomer',
    method: 'get',
    params,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: Apply for change
 * https://api-doc.displayten.com.cn/project/81/interface/api/3512
 * @param {*} data
 * @return {*}
 */
export function changeContractApi(data) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/serviceContract/changeContract',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: process contract
 * https://api-doc.displayten.com.cn/project/81/interface/api/3532
 * @param {*} params
 * @return {*}
 */
export function applyContractApi(data) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/serviceContract/applyContract',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: withdraw contract
 * https://api-doc.displayten.com.cn/project/81/interface/api/3536
 * @param {*} params
 * @return {*}
 */
export function revokeWithdrawContractApi(params) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/serviceContract/withdrawContract',
    method: 'get',
    params,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: refuse contract
 * https://api-doc.displayten.com.cn/project/81/interface/api/3540
 * @param {*} params
 * @return {*}
 */
export function refuseContractApi(data) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/serviceContract/refuseContract',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: 获取Settings配置
 * https://api-doc.displayten.com.cn/project/194/interface/api/3568
 * @param {*} params
 * @return {*}
 */
export function getSettingsConfigApi(params) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/deviceselftchecktimingconfig/getSettingsConfig',
    method: 'get',
    params,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
/**
 * @description: 获取 Lock Dashboard Setting For Company
 * https://api-doc.displayten.com.cn/project/194/interface/api/3568
 * @param {*} params
 * @return {*}
 */
export function getLockDashboardConfig() {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/company-setting/pinconf',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: 根据customerId获取device列表和device group 列表
 * @param {*} params
 * @return {*}
 */
export function getDeviceAndGroupApi(params) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/serviceContract/getDeviceAndGroup',
    method: 'get',
    params,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description:
 * https://api-doc.displayten.com.cn/mock/126/channel-service/v1/email/action/{actionType}
 * @param {*} params
 * @return {*}
 */
export function logActionEmailApi(params) {
  const actionType = `SIGN_IN`
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: `/pls/mrpt/${actionType}?c_param=${params.c_param}`,
    method: 'put'
  })
}

/**
 * @description: customer 获取自己的合作伙伴信息 Partner Account Profile
 * @param {*} params
 * @return {*}
 */
export function queryPartnerApi(params) {
  return fetchRequest({
    baseURL: API_DEVICE_USER,
    url: `/account/getPartnerAccountProfile/${params.accountId}`,
    method: 'get'
  })
}

/**
 * @description: mate解绑
 * @param {*} data
 * @return {*}
 */
export function unbindAccessoryApi(data) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/operator/unbindAccessory',
    method: 'post',
    data
  })
}

/**
 * @description: 添加合同
 * https://api-doc.displayten.com.cn/project/81/interface/api/3384
 * 你帮忙把添加接口  ：
/device-manager/serviceContract/contract改成
/device-manager/serviceContract/orbitContract  
需要和skymap那边做个区分
 * @param {*} data
 * @return {*}
 */
export function createContractApi(data) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/serviceContract/orbitContract',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: Partner 邀请记录增加
 * https://api-doc.displayten.com.cn/project/81/interface/api/3760
 * @param {*} data
 * @return {*}
 */
export function inviteApi(data) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/partnerInvite/record/add',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description:
 * https://api-doc.displayten.com.cn/project/81/interface/api/3764
 * @param {*} data
 * @return {*}
 */
export function getInviteHistoryListApi(data) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/partnerInvite/record/list',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description:
 * https://api-doc.displayten.com.cn/project/81/interface/api/3783
 * @param {*} data
 * @return {*}
 */
export function updateDeviceListColumnApi(data) {
  return fetchRequest({
    baseURL: API_DEVICE_MANAGER,
    url: '/colSelection/record/upd',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description:
 * https://api-doc.displayten.com.cn/project/81/interface/api/3783
 * @param {*} data
 * @return {*}
 */
export function uploadCertificateApi(data, onUploadProgress) {
  return fetchRequest({
    baseURL: API_DEVICE_OPERATOR,
    url: '/operator/batchUpdateCertificate',
    timeout: UPLOADTIMEOUT,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  })
}

export function fetchSiemConfig() {
  return fetchRequest({
    baseURL: API_SIEM,
    url: '/alert-configs',
    method: 'GET'
  })
}

export function updateSiemConfig(data) {
  return fetchRequest({
    baseURL: API_SIEM,
    url: '/alert-configs',
    method: 'PUT',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * @description: Get SIEM Alerts list
 * @param {*} data
 * @return {*}
 */
export function loadSiemAlerts(data) {
  return fetchRequest({
    baseURL: API_SIEM,
    url: '/alerts',
    method: 'get',
    params: data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
